@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.button {
  border-radius: 25px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 auto;
  background-color: var(--violet_medium);
  color: white;
  width: 350px;
  text-align: center;
  cursor: pointer;
  border: medium solid transparent;
  position: relative;
  transition: 0.2s;
  @include breakpoint(sm) {
    width: 100%;
  }
}

.button:hover {
  border: medium solid var(--violet_light);
}

.button:hover > .icon {
  right: 12px;
}

.icon {
  position: absolute;
  right: 10px;
  top: 60%;
  font-size: 25px;
  transform: translate(0, -50%);
  transition: 0.2s;
}
