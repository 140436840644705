@import "../../../global.css";
.button {
  border-radius: 25px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 auto;
  background-color: var(--violet_medium);
  color: white;
  width: 350px;
  text-align: center;
  cursor: pointer;
  border: medium solid transparent;
  position: relative;
  transition: 0.2s;
}
@media (max-width: 576px) {
  .button {
    width: 100%;
  }
}

.button:hover {
  border: medium solid var(--violet_light);
}

.button:hover > .icon {
  right: 12px;
}

.icon {
  position: absolute;
  right: 10px;
  top: 60%;
  font-size: 25px;
  transform: translate(0, -50%);
  transition: 0.2s;
}/*# sourceMappingURL=styles.module.css.map */