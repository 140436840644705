@import "../../../global.css";
.add_case_page {
  margin-top: 8rem;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  margin-right: 50px;
  flex: 50%;
}

.info {
  margin-bottom: 25px;
}

.image {
  background-color: wheat;
  border-radius: 1rem;
  height: 500px;
  width: 500px;
}/*# sourceMappingURL=styles.module.css.map */