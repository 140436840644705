@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.layout {
  padding: 0 15px;
  margin: 0 auto;
  width: 80vw;
  max-width: 1920px;
  @include breakpoint(xxl) {
    width: 90vw;
  }
  @include breakpoint(xl) {
    width: 95vw;
  }
  @include breakpoint(lg) {
  }
  @include breakpoint(md) {
    width: 100%;
  }
  @include breakpoint(sm) {
  }
}
