@import "../../../global.css";
.bread_crumbs {
  left: 45px;
  position: absolute;
  top: 70px;
  color: var(--black);
  background-color: rgb(255, 255, 255);
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  border-radius: 1rem;
}
.bread_crumbs_top {
  top: 110px;
}
.bread_crumbs_item {
  margin-right: 0.5rem;
  display: flex;
  height: 2.5rem;
  align-items: center;
}

.home {
  border-radius: 50%;
  border: 1px solid var(--black);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
}

.chevron {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.spiner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
  z-index: 2;
  background: rgba(255, 255, 255, 0.162);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  text-align: center;
  padding: 2rem 3rem;
  border-radius: 2rem;
}
.spiner span {
  margin-top: 3rem;
}/*# sourceMappingURL=styles.module.css.map */