@import "../../../global.css";
.header_mini {
  background-color: white;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  border-bottom: 1px solid rgb(211, 211, 211);
  color: var(--black);
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 15px;
}
.inner_mini {
  width: 100%;
  position: relative;
  height: 40px;
}
@media (max-width: 768px) {
  .inner_mini {
    height: 50px;
  }
}

.info {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .info {
    font-size: 12px;
    padding: 0 55px;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 70%;
  transform: translate(0%, -50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 10px;
}/*# sourceMappingURL=styles.module.css.map */