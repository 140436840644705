@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.add_case_page {
  margin-top: 8rem;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  margin-right: 50px;
  flex: 50%;
}

.info {
  margin-bottom: 25px;
}

.image {
  background-color: wheat;
  border-radius: 1rem;
  height: 500px;
  width: 500px;
}
