@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.header {
  &_mini {
    // background-color: rgba(255, 255, 255, 0.3);
    background-color: white;

    backdrop-filter: blur(8px);
    border-bottom: 1px solid rgb(211, 211, 211);
    // color: white !important;
    color: var(--black);
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  &_mini {
    width: 100%;
    position: relative;
    height: 40px;
    // background: linear-gradient(
    //   43deg,
    //   var(--violet_light) 0%,
    //   var(--violet_medium) 46%,
    //   #594e6f 100%
    // );
    @include breakpoint(md) {
      height: 50px;
    }
  }
}

.info {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  @include breakpoint(md) {
    font-size: 12px;
    padding: 0 55px;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 70%;
  transform: translate(0%, -50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 10px;
}
