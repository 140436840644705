@import "../../../global.css";
.sliders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
  position: relative;
}
@media (max-width: 992px) {
  .sliders {
    flex-direction: column;
  }
}
.items {
  width: 40%;
  margin-right: 5%;
  transition: 0.2s;
}
@media (max-width: 1200px) {
  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .items {
    flex-direction: column;
  }
}

.item {
  background-color: var(--violet_medium);
  height: 250px;
  border-radius: 25px;
  color: var(--white);
  padding: 10px 15px;
  margin: 25px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .item {
    width: 33%;
  }
}
@media (max-width: 992px) {
  .item {
    height: 150px;
    justify-content: start;
  }
}
@media (max-width: 768px) {
  .item {
    margin: 5px;
  }
}
@media (max-width: 576px) {
  .item {
    width: 100%;
    height: auto;
  }
}
.item_text {
  font-size: 25px;
  letter-spacing: -1px;
  text-align: center;
}
@media (max-width: 1200px) {
  .item_text {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .item_text {
    font-size: 16px;
  }
}

.count {
  font-size: 100px;
  letter-spacing: -5px;
}
@media (max-width: 1400px) {
  .count {
    font-size: 80px;
  }
}
@media (max-width: 992px) {
  .count {
    font-size: 60px;
    letter-spacing: -3px;
  }
}
@media (max-width: 768px) {
  .count {
    font-size: 40px;
    letter-spacing: -1px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .count {
    margin-bottom: 0;
  }
}

.for {
  margin-top: 10px;
  width: 55%;
  height: 100%;
  z-index: -1;
}
@media (max-width: 1200px) {
  .for {
    width: calc(100vw - 5px);
    margin: 0;
    position: absolute;
    top: 300px;
    left: -5vw;
  }
}
@media (max-width: 992px) {
  .for {
    font-size: 60px;
    letter-spacing: -3px;
    top: 200px;
  }
}
@media (max-width: 768px) {
  .for {
    top: 175px;
  }
}
@media (max-width: 576px) {
  .for {
    top: 300px;
  }
}/*# sourceMappingURL=styles.module.css.map */