@import "../../../global.css";
.menu {
  display: none;
  font-size: 30px;
  margin-left: 50px;
  padding: 5px;
  transition: 0.2s;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .menu {
    display: block;
  }
}
@media (max-width: 576px) {
  .menu {
    margin-left: 25px;
    height: 40px;
    border-radius: 50%;
  }
}

.menu:hover {
  opacity: 0.7;
}

.item {
  position: relative;
  transition: 0.2s;
}

.item_title {
  margin-bottom: 15px;
  font-size: 30px;
  background-color: transparent;
  color: var(--black);
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.item_title:hover {
  margin-left: 5px;
}

.select_active {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 10px;
  padding-top: 25px;
  transition: 0.2;
  max-width: 500px;
}
@media (max-width: 576px) {
  .select_active {
    max-width: 350px;
  }
}

.card {
  margin-bottom: 25px;
}
.card_img {
  font-size: 25px;
}
.card_title {
  font-size: 25px;
  margin-bottom: 15px;
}/*# sourceMappingURL=styles.module.css.map */