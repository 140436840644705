@import "../../../global.css";
.carusel_item {
  border-radius: 25px;
  margin: 25px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  width: 100%;
  height: 500px;
  min-width: 500px;
}
@media (max-width: 1400px) {
  .carusel_item {
    width: 100%;
  }
}

.title {
  font-size: 75px;
  letter-spacing: -2px;
  font-weight: bold;
  color: var(--black);
  height: 50%;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  padding: 25px;
  border-radius: 25px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, var(--violet_light) 100%);
}

.background {
  width: 100%;
  height: 500px;
  min-width: 500px;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 75%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
}/*# sourceMappingURL=styles.module.css.map */