@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.sliders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 100px;
  font-size: 25px;
  position: relative;
  @include breakpoint(lg) {
    flex-direction: column;
  }
  @include breakpoint(sm) {
    // margin-top: 50px;
  }
}

.items {
  width: 40%;
  margin-right: 5%;
  transition: 0.2s;
  @include breakpoint(xl) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.item {
  background-color: var(--violet_medium);
  height: 250px;
  border-radius: 25px;
  color: var(--white);
  padding: 10px 15px;
  margin: 25px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(xl) {
    width: 33%;
  }
  @include breakpoint(lg) {
    height: 150px;
    justify-content: start;
  }
  @include breakpoint(md) {
    margin: 5px;
  }
  @include breakpoint(sm) {
    width: 100%;
    height: auto;
  }
  &_text {
    font-size: 25px;
    letter-spacing: -1px;
    text-align: center;
    @include breakpoint(xl) {
      font-size: 20px;
    }
    @include breakpoint(sm) {
      font-size: 16px;
    }
  }
}

.count {
  font-size: 100px;
  letter-spacing: -5px;
  @include breakpoint(xxl) {
    font-size: 80px;
  }
  @include breakpoint(lg) {
    font-size: 60px;
    letter-spacing: -3px;
  }
  @include breakpoint(md) {
    font-size: 40px;
    letter-spacing: -1px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

.for {
  margin-top: 10px;
  width: 55%;
  height: 100%;
  z-index: -1;
  @include breakpoint(xl) {
    width: calc(100vw - 5px);
    margin: 0;
    position: absolute;
    top: 300px;
    left: -5vw;
  }
  @include breakpoint(lg) {
    font-size: 60px;
    letter-spacing: -3px;
    top: 200px;
  }
  @include breakpoint(md) {
    top: 175px;
  }
  @include breakpoint(sm) {
    top: 300px;
  }
}
