@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.menu {
  display: none;
  font-size: 30px;
  margin-left: 50px;
  padding: 5px;
  transition: 0.2s;
  cursor: pointer;
  @include breakpoint(xl) {
    display: block;
  }

  @include breakpoint(sm) {
    margin-left: 25px;
    height: 40px;
    border-radius: 50%;
  }
}

.menu:hover {
  opacity: 0.7;
}

// .drawer {
//   background-color: rgba(255, 255, 255, 0.298) !important;
//   backdrop-filter: blur(8px);
//   color: white !important;
// }

.item {
  position: relative;
  transition: 0.2s;
}

.item_title {
  margin-bottom: 15px;
  font-size: 30px;
  background-color: transparent;
  color: var(--black);
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.item_title:hover {
  margin-left: 5px;
}

.select_active {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 10px;
  padding-top: 25px;
  transition: 0.2;
  max-width: 500px;
  @include breakpoint(sm) {
    max-width: 350px;
  }
}

.card {
  margin-bottom: 25px;
  &_img {
    font-size: 25px;
  }
  &_title {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
