@import "../../../global.css";
.header {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 8px 0px;
  color: var(--black);
  border-bottom: 1px solid rgba(255, 255, 255, 0.108);
  transition: 0.2s;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  transition: 0.2s;
}
@media (max-width: 576px) {
  .inner {
    height: 60px;
  }
}

.logo {
  font-weight: bold;
  margin-right: 50px;
}

.info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.close {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0%, -50%);
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .items {
    display: none;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 5px;
}
.item span {
  margin-right: 5px;
}

.item:hover {
  background-color: white;
  border-radius: 10px;
  color: var(--violet_light);
}

.item_scrolled:hover {
  background-color: white;
  border-radius: 10px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_item {
  transition: 0.2s;
  cursor: pointer;
}
.content_item:hover {
  color: var(--violet_light);
}

.enter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
}
@media (max-width: 992px) {
  .enter {
    font-size: 1.5rem;
  }
}
.enter span {
  margin-left: 5px;
}
@media (max-width: 992px) {
  .enter_text {
    display: none;
  }
}

.select_active {
  position: absolute;
  top: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  left: 50%;
  margin: 0 auto;
  width: 70vw;
  transform: translate(-50%, 0px);
  border-radius: 20px;
  padding: 10px 15px;
  background-color: transparent;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1400px) {
  .select_active {
    flex-direction: column;
  }
}
.select_active_top {
  top: 90px;
}

.active {
  color: var(--violet_light);
  background-color: white;
  padding: 5px 15px;
  border-radius: 10px;
  transition: 0.2s;
}
.active_scrolled {
  background-color: var(--violet_light);
  color: white;
}

.card {
  border-radius: 25px;
  padding: 10px 15px;
  background-color: var(--violet);
  color: white;
  margin: 10px;
  font-size: 14px;
}
.card_img {
  font-size: 50px;
}
.card_title {
  font-size: 35px;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.add {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  margin-right: 25px;
}
.add_primary {
  color: var(--violet_light);
}/*# sourceMappingURL=styles.module.css.map */