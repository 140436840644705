@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.enter_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1.5rem;
  transition: 0.2s;
  cursor: pointer;

  @include breakpoint(lg) {
    font-size: 1.5rem;
  }

  &:hover {
    color: var(--violet_light);
  }
  span {
    margin-left: 5px;
  }
}

.social_login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.social_item {
  text-decoration: none;
  background: white;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.223);
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid black;
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.enter {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
}

.number_input {
  border-radius: 10px;
}

.registration_text {
  @include breakpoint(lg) {
    display: none;
  }
}
