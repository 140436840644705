@import "../../../global.css";
.main {
  background: linear-gradient(170deg, var(--violet_light) 0%, var(--violet_medium) 46%, var(--gold) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}
.main_inner {
  padding-top: 50px;
  color: white;
  position: relative;
}
@media (max-width: 1400px) {
  .main_inner {
    padding-top: 50px;
  }
}
@media (max-width: 1200px) {
  .main_inner {
    padding: 0 15px;
    padding-top: 50px;
  }
}
@media (max-width: 992px) {
  .main_inner {
    padding-top: 75px;
  }
}
@media (max-width: 768px) {
  .main_inner {
    padding-top: 100px;
  }
}
@media (max-width: 576px) {
  .main_inner {
    width: 100%;
  }
}

.main_background {
  height: 25rem;
  width: 30rem;
  background-image: url(../../../../public/img/image\ 19.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
@media (max-width: 1200px) {
  .main_background {
    width: 27rem;
  }
}
@media (max-width: 992px) {
  .main_background {
    height: 40vh;
    width: 100%;
    background-position: center center;
    margin-top: 25px;
  }
}
@media (max-width: 768px) {
  .main_background {
    height: 30vh;
  }
}

.frame {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.068);
}
@media (max-width: 992px) {
  .frame {
    flex-direction: column;
    padding: 1rem;
  }
}

.slogan {
  font-size: 75px;
  letter-spacing: -1px;
  font-weight: bold;
  width: 45rem;
  line-height: 100%;
  padding-bottom: 15px;
  border-bottom: medium solid var(--violet_medium);
  margin-right: 3rem;
}
@media (max-width: 1400px) {
  .slogan {
    font-size: 4rem;
    width: 40rem;
  }
}
@media (max-width: 1200px) {
  .slogan {
    font-size: 3.5rem;
    width: 30rem;
    margin-right: 1rem;
  }
}
@media (max-width: 992px) {
  .slogan {
    padding: 35px;
    width: auto;
    font-size: 3rem;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .slogan {
    font-size: 2.5rem;
    padding: 2rem;
    padding-left: 0;
  }
}

.sub_slogan {
  font-size: 25px;
  text-align: center;
  width: 600px;
  font-family: 400;
  margin: 25px auto;
}
@media (max-width: 1200px) {
  .sub_slogan {
    width: auto;
    font-size: 20px;
    width: 500px;
  }
}
@media (max-width: 768px) {
  .sub_slogan {
    font-size: 1rem;
    width: 75vw;
    margin-top: 50px;
  }
}
@media (max-width: 576px) {
  .sub_slogan {
    width: 90vw;
  }
}

.sliders {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.items {
  width: 40vw;
}

.item {
  background-color: var(--violet_medium);
  height: 200px;
  border-radius: 10px;
  color: var(--white);
  padding: 10px 15px;
  margin: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.item_text {
  font-size: 25px;
  letter-spacing: -1px;
}

.count {
  font-size: 100px;
}

.for {
  width: 60vw;
  height: 100%;
  z-index: -1;
}

.icon {
  font-size: 50px;
  margin: 0 auto;
  width: 100%;
  margin: 15px;
  animation: shake 5s;
  animation-iteration-count: infinite;
  margin-left: -10px;
}
@media (max-width: 576px) {
  .icon {
    margin-left: 2px;
    font-size: 40px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}/*# sourceMappingURL=styles.module.css.map */