@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.switch_container {
  display: flex;
  align-items: center;
  width: 400px;
  margin: 50px auto;
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: space-between;
  @include breakpoint(sm) {
    width: auto !important;
    font-size: 16px;
  }
}

.button {
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 25px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  border: medium solid transparent;
  position: relative;
  transition: 0.2s;
}

.button:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.active {
  background-color: var(--violet_light);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: medium solid var(--violet_medium);
}

.icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
}
