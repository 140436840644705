@mixin breakpoint($point) {
  @if $point ==lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point ==md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point ==sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $point ==xs {
    @media (max-width: 370px) {
      @content;
    }
  } @else if $point ==xl {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $point ==xxl {
    @media (max-width: 1400px) {
      @content;
    }
  }
}

@import "../../../global.css";

.bread_crumbs {
  left: 45px;
  position: absolute;
  top: 70px;
  color: var(--black);
  background-color: rgb(255, 255, 255);
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  border-radius: 1rem;

  &_top {
    top: 110px;
  }

  &_item {
    margin-right: 0.5rem;
    display: flex;

    height: 2.5rem;
    align-items: center;
  }
}

.home {
  border-radius: 50%;
  border: 1px solid var(--black);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
}

.chevron {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.spiner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // height: 100%;
  font-size: 0.75rem;
  z-index: 2;
  background: rgba(255, 255, 255, 0.162);
  backdrop-filter: blur(8px);
  text-align: center;
  padding: 2rem 3rem;
  border-radius: 2rem;

  span {
    margin-top: 3rem;
  }
}
